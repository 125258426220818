import { useState } from "react";
import bellQuestionMark from '../../assets/images/bell-question-mark.png';
import styles from './Updates.module.css';
import Update from './Update.jsx';
import SearchOrder from "../SearchOrder";
import Button from "../Button";

const Updates = (props) => {
    const [sortBy, setSortBy] = useState('newest');
    const [sortByLabel, setSortByLabel] = useState('Newest');
    const filterOptions = [{
        label: 'Newest',
        value: 'newest'
    }, {
        label: 'Oldest',
        value: 'oldest'
    }];

    const updateSort = (label, value) => {
        setSortByLabel(label);
        setSortBy(value);
    };

    const sortByOrder = (a, b) => {
        if(sortBy === 'newest'){
            if(a.created_at > b.created_at){
                return -1;
            }
            else if(a.created_at < b.created_at){
                return 1;
            }
            return 0;
        }
        else if(sortBy === 'oldest'){
            if(a.created_at < b.created_at){
                return -1;
            }
            else if(a.created_at > b.created_at){
                return 1;
            }
            return 0;
        }
    };

	return (
		<div className={props.className} style={props.style}>
            <div className={styles.topRow}>
                <h2 className={styles.header}>
                    Updates ({props.updates.length})
                </h2>
                {
                    props.updates.length > 1 && (
                        <SearchOrder className={styles.sortOrder} label={sortByLabel} options={filterOptions} value={sortBy} update={updateSort}/>
                    )
                }
            </div>
            {
                props.updates.length > 0 && (
                    <div className={styles.updatesContainer}>
                        <Button className={styles.button} text="Post update" dark={true} onClick={props.setShowAddUpdateModal}/>
                        <div className={styles.updates}>
                            {
                                props.updates.sort(sortByOrder).map((update, index) => (
                                    <Update className={styles.update} update={update} index={index} key={'update-' + update.id}/>
                                ))
                            }
                        </div>
                    </div>
                )
            }
            {
                props.updates.length === 0 && (
                    <div className={styles.noUpdates}>
                        <img className={styles.bellQuestionMark} src={bellQuestionMark} alt="Bell Question Mark"/>
                        <div className={styles.noUpdatesText}>
                            No updates yet
                        </div>
                        <Button className={styles.button} text="Post your first update" dark={true} onClick={props.setShowAddUpdateModal}/>
                    </div>
                )
            }
        </div>
    )
};

export default Updates;