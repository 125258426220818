// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BottomViewHeaders_container__MfMi0{
    padding-bottom: 15px;
}
.BottomViewHeaders_header__kjKTf{
    padding-bottom: 12.5px;
    color: #1F363D;
    font-size: 16px;
    font-family: 'Gotham Medium', serif;
    cursor: pointer;
}
.BottomViewHeaders_header__kjKTf:not(:last-child){
    margin-right: 30px;
}
.BottomViewHeaders_selected__mtw\\+a{
    color: #274C77;
    border-bottom: 3px solid #274C77;
}`, "",{"version":3,"sources":["webpack://./src/components/fundraiser/BottomViewHeaders.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;AACA;IACI,sBAAsB;IACtB,cAAc;IACd,eAAe;IACf,mCAAmC;IACnC,eAAe;AACnB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,gCAAgC;AACpC","sourcesContent":[".container{\n    padding-bottom: 15px;\n}\n.header{\n    padding-bottom: 12.5px;\n    color: #1F363D;\n    font-size: 16px;\n    font-family: 'Gotham Medium', serif;\n    cursor: pointer;\n}\n.header:not(:last-child){\n    margin-right: 30px;\n}\n.selected{\n    color: #274C77;\n    border-bottom: 3px solid #274C77;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BottomViewHeaders_container__MfMi0`,
	"header": `BottomViewHeaders_header__kjKTf`,
	"selected": `BottomViewHeaders_selected__mtw+a`
};
export default ___CSS_LOADER_EXPORT___;
