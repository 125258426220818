import { useState } from 'react';
import classNames from 'classnames';
import close from '../../assets/images/close.png';
import styles from './AddUpdateModal.module.css';
import Button from '../Button.jsx';
import FormInput from "../form/FormInput";
import FormTextEditor from "../form/FormTextEditor";
import FormError from "../form/FormError";
import FormButton from "../form/FormButton";
import {submitDonationApi} from "../../api/fundraisers";

const AddUpdateModal = (props) => {
    const [formData, setFormData] = useState({
        title: '',
        text: ''
    });
    const initialErrorObject = {
        title: false,
        text: false
    };
    const [errorObject, setErrorObject] = useState(initialErrorObject);
    const inputsMessagesErrorObject = {
        title: 'Required',
        text: 'Required'
    };
    const [submissionInProgress, setSubmissionInProgress] = useState(false);

    const checkForEmptyInputs = () => {
        setErrorObject(initialErrorObject);
        const newErrorObject = structuredClone(initialErrorObject);
        let foundError = false;
        for(const key of Object.keys(formData)){
            newErrorObject[key] = inputsMessagesErrorObject[key];
            foundError = true;
        }
        setErrorObject(newErrorObject);
        return foundError;
    };

    const submit = async(event) => {
        event.preventDefault();
        setErrorObject(initialErrorObject);
        setSubmissionInProgress(true);
        if(checkForEmptyInputs()){
            setSubmissionInProgress(false);
            return;
        }
        const submitDonationResponse = await submitDonationApi();
        let body;
        try{
            body = await submitDonationResponse.json();
        }
        catch(e){}
        if(submitDonationResponse && submitDonationResponse.ok){
        }
        else{
            const error = body?.error ? body.error : 'Add update failed';
            setErrorObject((prevState) => ({...prevState, apiError: error}));
        }
        setSubmissionInProgress(false);
    };

    return (
        <div className={styles.modal}>
            <div className={styles.container}>
                <div className={styles.header}>
                    Post update
                </div>
                <img className={styles.close} src={close} alt="Close" onClick={props.closeModals}/>
                <form className={styles.form} onSubmit={submit}>
                    <FormInput className={styles.input} label="Title" type="text" placeholder="Title" value={formData.title}
                               update={(event) => setFormData({...formData, title: event.target.value})} error={errorObject.title} required/>
                    <FormTextEditor className={styles.textEditor} label="Text" placeholder="1000 characters max" maxlength={1000} value={formData.text}
                                    update={(event) => setFormData({...formData, text: event.target.value})} error={errorObject.text} required/>
                    {
                        errorObject.apiError && (
                            <FormError error={errorObject.apiError}/>
                        )
                    }
                    <div className={classNames(styles.buttons, {[styles.buttonsApiError]: errorObject.apiError})}>
                        <Button className={styles.button} text="Close" onClick={props.closeModals}/>
                        <FormButton className={styles.button} style={{marginLeft: 'auto'}} text="Donate" disabled={submissionInProgress}/>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default AddUpdateModal;