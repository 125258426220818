// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrganizationUrl_label__-0uID{
    display: block;
    color: #1F363D80;
    margin-left: 10px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
}
.OrganizationUrl_urlRow__uasWl{
    display: flex;
}
.OrganizationUrl_url__Od-9H{
    margin-left: 10px;
    margin-right: 15px;
    color: #1F363D;
    font-size: 16px;
    font-family: 'Gotham Medium', serif;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.OrganizationUrl_copy__PHUvp, .OrganizationUrl_copyStatus__otQQR{
    margin-left: auto;
    cursor: pointer;
}
.OrganizationUrl_copy__PHUvp{
    height: 20px;
    width: 17px;
}
.OrganizationUrl_copyStatus__otQQR{
    display: flex;
    position: relative;
    top: 4px;
}
.OrganizationUrl_copyStatusIcon__N53tk{
    height: 12px;
    width: 16px;
}
.OrganizationUrl_copyStatusText__\\+J8cz{
    margin-left: 5px;
    color: #40798C;
    font-size: 12px;
    font-family: 'Gotham Medium', serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/profile/OrganizationUrl.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,mCAAmC;IACnC,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,QAAQ;AACZ;AACA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,mCAAmC;AACvC","sourcesContent":[".label{\n    display: block;\n    color: #1F363D80;\n    margin-left: 10px;\n    margin-bottom: 8px;\n    font-size: 14px;\n    line-height: 14px;\n}\n.urlRow{\n    display: flex;\n}\n.url{\n    margin-left: 10px;\n    margin-right: 15px;\n    color: #1F363D;\n    font-size: 16px;\n    font-family: 'Gotham Medium', serif;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n.copy, .copyStatus{\n    margin-left: auto;\n    cursor: pointer;\n}\n.copy{\n    height: 20px;\n    width: 17px;\n}\n.copyStatus{\n    display: flex;\n    position: relative;\n    top: 4px;\n}\n.copyStatusIcon{\n    height: 12px;\n    width: 16px;\n}\n.copyStatusText{\n    margin-left: 5px;\n    color: #40798C;\n    font-size: 12px;\n    font-family: 'Gotham Medium', serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `OrganizationUrl_label__-0uID`,
	"urlRow": `OrganizationUrl_urlRow__uasWl`,
	"url": `OrganizationUrl_url__Od-9H`,
	"copy": `OrganizationUrl_copy__PHUvp`,
	"copyStatus": `OrganizationUrl_copyStatus__otQQR`,
	"copyStatusIcon": `OrganizationUrl_copyStatusIcon__N53tk`,
	"copyStatusText": `OrganizationUrl_copyStatusText__+J8cz`
};
export default ___CSS_LOADER_EXPORT___;
