// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Comments_header__98j9L{
    margin-bottom: 15px;
    color: #1F363D;
    font-size: 32px;
    font-family: 'Gotham Bold', serif;
}
@media(max-width: 600px){
    .Comments_header__98j9L{
        font-size: 24px;
    }
}
.Comments_description__xGNRr{
    color: #1F363D;
}
.Comments_comments__XkARZ{
    margin-top: 25px;
}
@media(max-width: 600px){
    .Comments_comments__XkARZ{
        margin-top: 15px;
    }
}
.Comments_comment__Z4-Cn{
    margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/fundraiser/Comments.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,iCAAiC;AACrC;AACA;IACI;QACI,eAAe;IACnB;AACJ;AACA;IACI,cAAc;AAClB;AACA;IACI,gBAAgB;AACpB;AACA;IACI;QACI,gBAAgB;IACpB;AACJ;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".header{\n    margin-bottom: 15px;\n    color: #1F363D;\n    font-size: 32px;\n    font-family: 'Gotham Bold', serif;\n}\n@media(max-width: 600px){\n    .header{\n        font-size: 24px;\n    }\n}\n.description{\n    color: #1F363D;\n}\n.comments{\n    margin-top: 25px;\n}\n@media(max-width: 600px){\n    .comments{\n        margin-top: 15px;\n    }\n}\n.comment{\n    margin-bottom: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Comments_header__98j9L`,
	"description": `Comments_description__xGNRr`,
	"comments": `Comments_comments__XkARZ`,
	"comment": `Comments_comment__Z4-Cn`
};
export default ___CSS_LOADER_EXPORT___;
